import { ReactNode, useEffect, useState } from "react";
import { createContext } from "../../../../hooks/useCreateContext";
import { useApp } from "../../../../contexts/AppContext";
import Placeholder from "../../../../components/Placeholder";
import { DateTime, Task, fetchGet, fetchPost } from "../../../../utils";
import { User } from "../../../../utils/Users";
import { AuthRoles } from "../../../../utils/AuthRoles";
import alertMessages from "../../../../data/alertMessages.json";
import apiUrls from "../../../../data/api.json";
import {
  createSearchParams,
  generatePath,
  useNavigate,
} from "react-router-dom";
import { useUsers } from "../../../../contexts/UsersContext";
import { fetchers } from "../../../../serverApi/fetchers";

type MembersInfo = {
  member: User;
  timer: {
    startedAt: DateTime;
    task: Task;
    durationForDayMinutes: number;
    acceptibility: number;
  } | null;
  totalBillableMinutes: number;
}[];
type ActiveMembersContextVal = {
  date: DateTime;
  membersInfo: MembersInfo;
  refreshActiveMembers: () => void;
  stopMemberTimer: (member: User) => void;
  navigateToMemberDayTimeRecords: (member: User) => void;
};
const [ActiveMembersContext, useActiveMembers] =
  createContext<ActiveMembersContextVal>();
export { useActiveMembers };

type ActiveMembersProviderProps = {
  children: ReactNode;
};

export function ActiveMembersProvider({
  children,
}: ActiveMembersProviderProps) {
  const navigate = useNavigate();
  const { showAlert } = useApp();
  const [date, setDate] = useState<DateTime | undefined>(undefined);
  const [membersInfo, setMembersInfo] = useState<MembersInfo | undefined>(
    undefined
  );
  const refreshActiveMembers = () => {
    fetchers.account.admin.activeMembersInfo.fetch({}, {
      success: (data, status) => {
        setDate(data.date)
        const existedMembersData = data.membersInfo.filter(info => !info.member.deleted)
        setMembersInfo(existedMembersData)
      },
      fail: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      }
    })
  };

  useEffect(() => {
    refreshActiveMembers();
  }, []);

  const navigateToMemberDayTimeRecords = (member: User) => {
    if(!date) {
      showAlert(alertMessages.somethingWentWrong);
      return;
    }
    const dayValNum = date.getLocDay();
    const dayVal = dayValNum.toString().padStart(2, "0"); // < 10 ? "0" + dayValNum : "" + dayValNum;
    const monthValNum = date.getLocMonth();
    const monthVal = monthValNum.toString().padStart(2, "0"); // < 10 ? "0" + monthValNum : "" + monthValNum;
    const queryParams = createSearchParams({
      date: dayVal + "." + monthVal + "." + date.getLocYear()
    }).toString()
    // console.log(queryParams)
    navigate({
      pathname: generatePath(apiUrls.account.children.admin.children.memberDay, {
        userId: member.id,
      }),
      search: queryParams
    });
  }

  const stopMemberTimer = (member: User) => {
    const dataToSend = {
      userId: member.id,
    };
    fetchers.account.admin.endMemberTimer.fetch(dataToSend,{
      success: () => {},
      fail: (data, status) => {
        if (status == 450) {
          showAlert(alertMessages.noTimerRunning);
          return;
        }
        if (status == 444) {
          showAlert(alertMessages.timerDurationIsTooShort);
          return;
        }
        if (status === 445) {
         
          return;
        }
        showAlert(alertMessages.somethingWentWrong);
      },
      always: () => {
        refreshActiveMembers();
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
    })
    // setIsDisabled(true);
    // const endpoint = "/admin/active-members/end-member-timer";
    // type RespType = {};
    // fetchPost<RespType>(endpoint, dataToSend, {
    //   success: () => {},
    //   fail: (data, status) => {
    //     if (status == 450) {
    //       showAlert(alertMessages.noTimerRunning);
    //       return;
    //     }
    //     // if (status == 444) {
    //     //   showAlert(alertMessages.timerDurationIsTooShort);
    //     //   return;
    //     // }
    //     // if (status === 445) {
    //     //   showAlert(
    //     //     (alertMessages.changesNotSaved,
    //     //     alertMessages.timeRecordDurationIsLonger24H)
    //     //   );
    //     //   return;
    //     // }
    //     showAlert(alertMessages.somethingWentWrong);
    //   },
    //   always: () => {
    //     refreshActiveMembers();
    //   },
    //   error: () => {
    //     showAlert(alertMessages.somethingWentWrong);
    //   },
    // });
  };
  return (
    <>
      {date && membersInfo && (
        <ActiveMembersContext.Provider
          value={{
            date,
            membersInfo,
            refreshActiveMembers,
            stopMemberTimer,
            navigateToMemberDayTimeRecords
          }}
        >
          {children}
        </ActiveMembersContext.Provider>
      )}
      {(!date || !membersInfo) && <Placeholder />}
    </>
  );
}
