import { Col, Row } from "react-bootstrap";
import List from "../../../../../components/List";
import Table from "../../../../../components/Table";
import { useActiveMembers } from "../../context/ActiveMembersContext";
import { TimeCount } from "./components/TimeCount/TimeCount";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import {
  faCircleCheck,
  faCircle,
  faCircleStop,
} from "@fortawesome/free-regular-svg-icons";
import { faMagnifyingGlass, faInfo } from "@fortawesome/free-solid-svg-icons";
import "./MembersTable.scss";
import TooltipButton from "../../../../../components/TooltipButton";
import ButtonScale from "../../../../../components/ButtonScale";
import FullTaskName2 from "../../../../../components/FullTaskName2";
import _ from "lodash";
import { ListOfAccordions } from "../../../../../components/ListOfAccordions/ListOfAccordions";
import TimeHoursMinutes from "../../../../../components/TimeHoursMinutes";
import TooltipButton2 from "../../../../../components/TooltipButton2";
import { Formatter } from "../../../../../utils/Formatter";

export function MembersTable() {
  const { date, membersInfo, stopMemberTimer, navigateToMemberDayTimeRecords } = useActiveMembers();
  return (
    <div>
      <Table className="MembersTable">
        <Table.Head>
          <Row className="justify-content-between">
            {/* <Col xs={1} className="text-center">
              Active
            </Col> */}
            <Col xs={8} sm={4} md={3} className="d-flex align-self-center">
              <div className="me-4 invisible">
                <FontAwesomeIcon icon={faCircle} />
              </div>
              Member
            </Col>
            {/* <Col xs={1} className="text-end"></Col> */}
            <Col
              xs={undefined}
              sm={undefined}
              md={2}
              className="text-center d-none d-md-block align-self-center"
            >
              Current Task
            </Col>
            <Col
              xs={undefined}
              sm={4}
              md={3}
              className="text-center d-none d-md-block align-self-center"
            >
              Current Timer
            </Col>
            <Col
              xs={undefined}
              sm={4}
              md={1}
              className="text-center d-none d-md-block align-self-center"
            >
              Started Timer
            </Col>
            {/* <Col className="text-center">Recorded Day Worktime</Col> */}
            <Col xs={4} sm={4} md={3} className="text-center d-block align-self-center">
            Worked Today <span className="d-none d-sm-block d-lg-inline ms-sm-1">(+ Timer)</span>
            </Col>
            {/* <Col xs={1} className="text-center">
              
            </Col> */}
            {/* <Col xs={3} className="text-center">Action</Col> */}
          </Row>
        </Table.Head>
        <Table.Body className="d-none d-md-block">
          {membersInfo.map((memberInfo) => (
            <Row
              className={
                "justify-content-between align-items-center" +
                (memberInfo.timer
                  ? memberInfo.timer.acceptibility < 0
                    ? " row-invalid"
                    : memberInfo.timer.acceptibility === 0
                    ? " row-warning"
                    : " row-valid"
                  : "")
              }
              key={memberInfo.member.id}
            >
              {/* Online, Name */}
              <Col xs={8} sm={4} md={3} className="d-flex">
                <div className="me-3 is-active">
                  <span
                    className={
                      !!memberInfo.timer ? "active" : "empty non-active "
                    }
                  >
                    <FontAwesomeIcon
                      icon={!!memberInfo.timer ? faCircleCheck : faCircle}
                    />
                  </span>
                </div>
                <div>{memberInfo.member.lastName + ' ' + memberInfo.member.firstName}</div>
              </Col>

              {/* Current Task */}
              <Col
                xs={undefined}
                sm={4}
                md={2}
                className="d-flex justify-content-center d-none d-md-flex"
              >
                {!!memberInfo.timer ? (
                  <>
                    <FullTaskName2
                      categoryName={memberInfo.timer.task.category}
                      taskName={memberInfo.timer.task.name}
                    />
                    {/* {memberInfo.active.task.category + ": " + memberInfo.active.task.name} */}
                  </>
                ) : (
                  <span className="empty">---</span>
                )}
              </Col>

              {/* Current Timer */}
              <Col
                sx={undefined}
                sm={4}
                md={3}
                className="d-flex justify-content-center d-none d-sm-flex"
              >
                {!!memberInfo.timer ? (
                  <>
                    <div className="d-flex align-items-center">
                      <TimeCount
                        started_at={memberInfo.timer.startedAt}
                        date={date}
                      />
                      {/* <TooltipButton2 tooltipContent={"Started at: " + Formatter.formatDate(memberInfo.timer.startedAt) + "\u00A0" + Formatter.formatTime(memberInfo.timer.startedAt)} delay={{show: 100, hide: 300}} buttonVariant="light" className="ps-1">
                          <FontAwesomeIcon size="2xs" icon={faInfo} />
                        </TooltipButton2> */}
                    </div>
                    {/* {memberInfo.active.acceptibility < 0 &&  (<div className="ms-2 active">
                    <FontAwesomeIcon size="lg"
                        icon={faCircleStop}
                        className="stop-timer"
                      />
                    </div>)} */}
                    {memberInfo.timer.acceptibility < 0 && (
                      <div className="stop-timer">
                        <ButtonScale
                          parentClassName="row"
                          onClick={() => stopMemberTimer(memberInfo.member)}
                        >
                          <FontAwesomeIcon size="lg" icon={faCircleStop} />
                        </ButtonScale>
                      </div>
                    )}
                  </>
                ) : (
                  <span className="empty">---</span>
                )}
              </Col>
              {/* Started Timer */}
              <Col
                xs={4}
                sm={4}
                md={1}
                className="text-center d-flex justify-content-center align-items-center total-time"
              >
                {!!memberInfo.timer ? (
                  <div className="d-flex align-items-center flex-column">
                    <div className="started-date">{Formatter.formatDate(memberInfo.timer.startedAt)}</div>
                    <div>{Formatter.formatTime(memberInfo.timer.startedAt)}</div>
                  </div>
                  ):(
                  <>
                    <span className="empty">---</span>
                  </>
                )}
              </Col>
              {/* Total Time */}
              <Col
                xs={4}
                sm={4}
                md={3}
                className="text-center d-flex justify-content-center align-items-center total-time"
              >
                <div className="d-flex ps-3">
                  <div style={{ minWidth: "3.5rem" }}>
                    <TimeHoursMinutes totalMinutes={memberInfo.timer?.durationForDayMinutes ? memberInfo.timer.durationForDayMinutes + memberInfo.totalBillableMinutes : memberInfo.totalBillableMinutes} lightLetters={true} />
                  </div>
                  <div>
                      <TooltipButton
                        tooltipContent="Details"
                        buttonVariant="light"
                        className="openDay"
                        onClick={() => {
                          navigateToMemberDayTimeRecords(memberInfo.member);
                        }}
                        // disabled={day.isDisabled}
                      >
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                      </TooltipButton>
                    </div>
                </div>
              </Col>
            </Row>
          ))}
        </Table.Body>

        <Table.Body className="d-md-none mobile">
          <ListOfAccordions
            list={membersInfo}
            accordionHeader={(memberInfo, active) => (
              <Row
                className={
                  "justify-content-between align-items-center" +
                  (memberInfo.timer
                    ? memberInfo.timer.acceptibility < 0
                      ? " row-invalid"
                      : memberInfo.timer.acceptibility === 0
                      ? " row-warning"
                      : " row-valid"
                    : "")
                }
                key={memberInfo.member.id}
              >
                {/* Online, Name */}
                <Col xs={8} sm={4} md={3} className="d-flex align-items-center flex-fill">
                  <div className="me-3 me-sm-4 is-active">
                    <span
                      className={
                        !!memberInfo.timer ? "active" : "empty non-active "
                      }
                    >
                      <FontAwesomeIcon
                        icon={!!memberInfo.timer ? faCircleCheck : faCircle}
                        size="sm" className="d-md-none"
                      />
                      <FontAwesomeIcon
                        icon={!!memberInfo.timer ? faCircleCheck : faCircle}
                         className="d-none d-md-inline"
                      />
                    </span>
                  </div>
                  <div>{memberInfo.member.lastName + ' ' + memberInfo.member.firstName}</div>
                </Col>

                {/* Current Task */}
                <Col
                  xs={4}
                  sm={4}
                  md={3}
                  className="text-center d-flex justify-content-center align-items-center total-time"
                >
                  <>
                    <div style={{ minWidth: "3.5rem" }}>
                      <TimeHoursMinutes totalMinutes={memberInfo.timer?.durationForDayMinutes ? memberInfo.timer.durationForDayMinutes + memberInfo.totalBillableMinutes : memberInfo.totalBillableMinutes} lightLetters={true} />
                    </div>
                    <div>
                      <TooltipButton
                        tooltipContent="Details"
                        buttonVariant="light"
                        className="openDay"
                        onClick={() => {
                          navigateToMemberDayTimeRecords(memberInfo.member);
                        }}
                        // disabled={day.isDisabled}
                      >
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                      </TooltipButton>
                    </div>
                  </>
                </Col>
              </Row>
            )}
            accordionBody={(memberInfo) => (
              <div className={"d-flex flex-column" +  (memberInfo.timer
                ? memberInfo.timer.acceptibility < 0
                  ? " row-invalid"
                  : memberInfo.timer.acceptibility === 0
                  ? " row-warning"
                  : " row-valid"
                : "")}>

                {/* Current Task */}
                <div className="d-flex justify-content-start  align-items-center my-1">
                  <div className="me-2 label">Current Task: </div>
                  {!!memberInfo.timer ? (
                    <>
                      <FullTaskName2
                        categoryName={memberInfo.timer.task.category}
                        taskName={memberInfo.timer.task.name}
                      />
                    </>
                  ) : (
                    <span className="empty">---</span>
                  )}
                </div>

                {/* Current Timer */}
                <div className="d-flex justify-content-start  align-items-center my-1">
                  <div className="me-2 label">Current Timer: </div>
                  {!!memberInfo.timer ? (
                    <>
                      <div className="d-flex  align-items-center">
                        <TimeCount
                          started_at={memberInfo.timer.startedAt}
                          date={date}
                        />
                        {/* <TooltipButton2 tooltipContent={"Started at: " + Formatter.formatDate(memberInfo.timer.startedAt) + "\u00A0" + Formatter.formatTime(memberInfo.timer.startedAt)} delay={{show: 100, hide: 300}} buttonVariant="light" className="ps-1">
                          <FontAwesomeIcon size="2xs" icon={faInfo} />
                        </TooltipButton2> */}
                      </div>
                      {memberInfo.timer.acceptibility < 0 && (
                        <div className="stop-timer">
                          <ButtonScale
                            parentClassName="row"
                            onClick={() => stopMemberTimer(memberInfo.member)}
                          >
                            <FontAwesomeIcon size="lg" icon={faCircleStop} />
                          </ButtonScale>
                        </div>
                      )}
                    </>
                  ) : (
                    <span className="empty">---</span>
                  )}
                </div>


                {/* Startes Timer */}
                <div className="d-flex justify-content-start  align-items-center my-1">
                  <div className="me-2 label">Started Timer: </div>
                  {!!memberInfo.timer ? (
                    <>
                      <div className="d-flex  align-items-center">
                        <div className="d-flex align-items-center flex-row">
                          <div className="started-date-mobile pe-2">{Formatter.formatDate(memberInfo.timer.startedAt)}</div>
                          <div>{Formatter.formatTime(memberInfo.timer.startedAt)}</div>
                          </div>
                      </div>
                    </>
                  ):(
                    <span className="empty">---</span>
                  )}
                </div>
              
              </div>
            )}
          />
        </Table.Body>
      </Table>
    </div>
  );
}
