import { Button, Card } from "react-bootstrap";
import DatePicker from "../../../../../components/DatePicker";
import { DateTime } from "../../../../../utils";
import { useActiveMembers } from "../../context/ActiveMembersContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateRight } from "@fortawesome/free-solid-svg-icons";
import "./Header.scss";
import ButtonScale from "../../../../../components/ButtonScale";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { NavLink, To, createSearchParams } from "react-router-dom";
import apiUrls from "../../../../../data/api.json";


export function Header() {
  const { date, refreshActiveMembers } = useActiveMembers();

  const getDaySummayLink = (date: DateTime): To => {
    const dayValNum = date.getLocDay();
    const dayVal = dayValNum < 10 ? "0" + dayValNum : "" + dayValNum;
    const monthValNum = date.getLocMonth();
    const monthVal = monthValNum < 10 ? "0" + monthValNum : "" + monthValNum;
    const queryParams = createSearchParams({
      date: dayVal + "." + monthVal + "." + date.getLocYear()
    }).toString()
    return {
      pathname: apiUrls.account.children.admin.children.membersWorkingtimeSummary,
      search: queryParams
    };
  }
  return (
    <>
      {/* <div className="AccountSettingsHeader w-100 d-flex justify-content-center align-items-center border-bottom mb-1">
      <div className="title">Active Members</div>
    </div> */}
      {/* <div className="title">Active Members</div> */}
      <Card.Header className="Header d-flex justify-content-c-sm-start">
        <div className="w-100 d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <NavLink
              to={getDaySummayLink((new DateTime()).addDays(-1))}
              className={({ isActive, isPending }) => {
                return (
                  "d-inline-block w-100 no-st-lk" +
                  (isActive ? " active" : "")
                );
              }}
            >
              <Button
                variant="outline-light"
                size="sm"
                className={"arrow arrow-left"}
              >
                <FontAwesomeIcon icon={faCaretLeft} size="sm" className="" />
              </Button>
            </NavLink>
            <DatePicker
              date={date}
              setDate={undefined}
              format={"dayMonthYear"}
              maxDate={new DateTime()}
            />
          </div>
          {/* <div className="refresh">
          <Button onClick={refreshActiveMembers}>
            <FontAwesomeIcon icon={faRotateRight} />
          </Button>
        </div> */}
          <div>
            <ButtonScale onClick={refreshActiveMembers} className="py-0">
              <FontAwesomeIcon icon={faRotateRight} />
            </ButtonScale>
          </div>
        </div>
      </Card.Header>
    </>
  );
}
